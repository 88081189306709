import { SplitFactory, LocalhostFromObject } from '@splitsoftware/splitio-browserjs'

import Consts from 'consts'
import Storage from 'web-client/utils/storage'
import createUUID from 'web-client/utils/createUUID'
import rollbar from 'shared/utils/logging/integrations/rollbar'
import type { User } from 'web-client/hooks/useCurrentUser'
import datadog from 'shared/utils/logging/integrations/datadog'

let splitIOClient: SplitIO.IClient, treatments: SplitIO.Treatments
let isInitializing = false
let pendingCallbacks: Array<(treatments: SplitIO.Treatments, client: SplitIO.IClient) => void> = []

const getRandomIdFromStorage = (key: string) => {
  let id = Storage.get(key)

  if (!id) {
    id = createUUID()
    Storage.set(key, id)
  }

  return id
}

export const getSplitFactory = (user?: User, splitFilters: SplitIO.SplitFilter[] = []) => {
  const key = user?.id || getRandomIdFromStorage('temporarySplitId')

  let features = {}
  if (window.SPLITS) {
    features = { features: window.SPLITS }
  }

  const splitFactory = SplitFactory({
    core: {
      // file deepcode ignore HardcodedNonCryptoSecret: <please specify a reason of ignoring this>
      authorizationKey: Consts.SPLIT_KEY || 'localhost',
      key,
    },
    ...features,
    startup: {
      readyTimeout: Consts.SPLIT_TIMEOUT,
    },
    sync: {
      ...(!Consts.SPLIT_KEY &&
        process.env.NODE_ENV !== 'production' && {
          localhostMode: LocalhostFromObject(),
        }),
      splitFilters,
    },
  })

  return splitFactory
}

// To apply specific treatments based on custom attributes. Include values in the attributes below.
// Additionally, on split.io, you must setup targeting rules based on those attributes and
// allocate traffic to the split. If no traffic is allocated, the Default Treatment will be applied.
// If traffic is allocated and your rules do not match, the Default Rule will be applied.
const parseTreatments = (client: SplitIO.IClient, attributes: SplitIO.Attributes) => {
  const gottenTreatments = client.getTreatments(Object.values(Consts.SPLITS), attributes)
  const parsedTreatments: Record<string, boolean> = {}
  Object.keys(gottenTreatments).forEach((key) => {
    if (Consts.SWOOP_ENV === 'test' || Consts.SWOOP_ENV === 'development') {
      /* eslint-disable no-console -- Log the split values so we can see the state in ESTs, etc */
      console.info(`Split ${key}: ${gottenTreatments[key]}`)
    }
    if (gottenTreatments[key] === 'on') {
      parsedTreatments[key] = true
    } else if (gottenTreatments[key] === 'off') {
      parsedTreatments[key] = false
    }
  })
  return parsedTreatments
}

const initSplit = (
  user: User,
  callback: (treatments: SplitIO.Treatments, client: SplitIO.IClient) => void
) => {
  const attributes = {
    company_id: user?.company?.id ?? '',
    dynamic_environment: Consts.SWOOP_ENV,
  }

  if (isInitializing) {
    pendingCallbacks.push(callback)
  } else if (treatments) {
    callback(treatments, splitIOClient)
  } else {
    isInitializing = true

    const factory = getSplitFactory(user, [
      {
        type: 'byName',
        values: Object.values(Consts.SPLITS),
      },
    ])
    const client = factory.client()
    const cleanup = () => {
      isInitializing = false
      pendingCallbacks = []
    }
    let loadTime: number | null = Date.now()
    const success = () => {
      if (loadTime) {
        datadog.logInfo('Split initialized', {
          delta: Date.now() - loadTime,
        })
        loadTime = null
      }
      splitIOClient = client
      // @ts-expect-error
      treatments = parseTreatments(client, attributes)
      callback(treatments, splitIOClient)
      pendingCallbacks.forEach((initCallback) => initCallback(treatments, splitIOClient))

      cleanup()
    }

    client.on(client.Event.SDK_READY, success)
    client.on(client.Event.SDK_UPDATE, success)
    client.on(client.Event.SDK_READY_TIMED_OUT, () => {
      cleanup()
      rollbar.logError('SplitIO Util caught SDK_READY_TIMED_OUT event') // ... should we do something?
    })
  }
}

export { initSplit }
