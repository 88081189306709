import Api from 'web-client/utils/api'
import moment from 'moment-timezone'
import Consts from 'consts'
import ReactDOMFactories from 'react-dom-factories'
import MicroEvent from 'microevent-github'
import Storage from 'web-client/utils/storage'
import Dispatcher from 'shared/utils/dispatcher'
import Context from 'web-client/router/Context'
import { extend, find, intersection, partial } from 'lodash'
import { isUnitKm, getUnitSystem } from 'web-client/utils/localize'
import { localize } from 'shared/utils/languageUtils'
import logging from 'shared/utils/logging'
import auth0Service from 'web-client/utils/auth0'
import sessionService from 'web-client/utils/session'
import userService from 'shared/utils/user'
import { sendNotification } from 'design-system/components/Notifications/notificationUtils'
import callWhen from 'web-client/utils/callWhen'
import relayService from 'shared/utils/relay'

triggers = Consts.USER_STORE

if window?
  window[key] = val for key, val of triggers

UserStoreFunc = ( (init_data=null) ->
    #Variables on Store
    @user = init_data
    @error = null

    bearer_token = null
    refresh_token = null
    #Visible Variables
    store =
        loadUserIfNeeded: () => grabAndSetUser()
        setCompany: (data) =>
          # dirty hack to override `RATE_TYPE_MAP`
          @user.company = data
          if isUnitKm(UserStore.getCompany()?.distance_unit)
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_TOWED] = 'Kilometers (Towed)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_EN_ROUTE_ONLY] = 'Kilometers (En Route)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_EN_ROUTE] = 'Kilometers (En Route + Towed)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_P2P] = 'Kilometers (Port to Port)'
          else
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_TOWED] = 'Mileage (Towed)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_EN_ROUTE_ONLY] = 'Mileage (En Route)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_EN_ROUTE] = 'Mileage (En Route + Towed)'
            Consts.RATE_TYPE_MAP[Consts.MILEAGE_P2P] = 'Mileage (Port to Port)'

        getUser: =>
          @user

        getCompany: =>
          #TODO: This should return the ID and companyStore should be used to get attributes
          @user?.company

        getTimezone: =>
          tz = @user?.timezone
          if !tz?
            tz = moment.tz.guess()
          return tz

        getShortName: =>
          if @user?
            if @user.first_name?
                return @user.first_name
            if @user.username?
                return @user.username
            if @user.email?
                return @user.email
            return @user.full_name
          return ""

        createInstance: (data) =>
          return UserStoreFunc.bind({})(data)

        getError: =>
            @error
        hasRole: (role, user = @user) =>
            user? and user.roles? and role in user.roles
        isRoot: =>
            store.hasRole('root')
        isAdmin: (user = undefined) =>
            store.hasRole('admin', user)
        isDispatcher: =>
            store.hasRole('dispatcher')
        isOnlyDispatcher: =>
            store.isDispatcher() and not store.isAdmin() and not store.isDriver()
        isDriver: =>
            store.hasRole('driver')
        isPiiRestricted: =>
            store.hasRole('pii_restricted')
        isOnlyDriver: =>
            store.isDriver() and not store.isAdmin() and not store.isDispatcher()
        isPartner: =>
            @user?.company?.type == 'RescueCompany'

        isFleet: =>
            @user?.company?.type == 'FleetCompany'

        isFleetManaged: =>
            store.isFleet() and @user?.company?.in_house != true

        isRescueDriver: (job) =>
          return @user?.id == job?.rescue_driver_id

        updateUser: (data) =>
          if(store.getUser() && store.getUser().id == data.id)
            store.setUser(extend({}, @user, data))

        isOnDuty: () => @user?.on_duty == true

        setUser: (data) =>
            @user = data
            Storage.set("user", data)
            store.trigger(CHANGE)
            store.trigger(USERME_CHANGED)

            v = navigator?.userAgent?.match(/Chrome\/(\S+)/)
            if v?
              if parseFloat(v[1]) < 53
                setTimeout(=>
                  sendNotification(
                    ReactDOMFactories.a
                      target: "_blank"
                      href: "https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=en"
                      "You are using an unsupported version of Chrome. Click for instructions.",
                    Consts.NOTIFICATION_TYPES.ERROR,
                  )
                , 1000)

        isFlightCar: =>
            if @user? and @user.company? and @user.company.name?
                return @user.company.name == "FlightCar"
            return false

        isFleetInHouse: =>
            store.isFleet() and @user?.company?.in_house == true

        isMotorClub: =>
            store.isFleet() and @user?.company?.name in Consts.MOTORCLUBS

        isCompany: (name) =>
          if @user?
            return @user?.company?.name == name
          return false

        userHasPaymentSettingsEnabled: =>
          return store.isAdmin() && @user.custom_account_visible

        hasPermissionsToCharge: =>
          perm = find(@user.company.role_permissions, (perm) -> perm.type == "charge")
          if !perm?
            return false
          return intersection(@user.role_ids, perm.role_ids).length > 0

        hasPermissionsToRefund: =>
          perm = find(@user.company.role_permissions, (perm) -> perm.type == "refund")
          if !perm?
            return false
          return intersection(@user.role_ids, perm.role_ids).length > 0
        isLincoln: => store.isCompany("Lincoln")
        isInfiniti: => store.isCompany("INFINITI")
        isSouthern: => store.isCompany("Southern Wrecker & Recovery")
        isTrekker: => store.isCompany("Trekker Group")
        isMotorcycleTowingService: => store.isCompany("Motorcycle Towing Services, L.C.")
        isMetromile: => store.isCompany("Metromile")
        isFleetResponse: => store.isCompany("Fleet Response")
        isUSAA: => store.isCompany("USAA")
        isFinishLine: => store.isCompany("Finish Line Towing")
        isSwoop: => store.isCompany("Swoop")
        isEnterpriseFleetManagement: => store.isCompany(Consts.ENTERPRISE_FLEET_MANAGEMENT)
        isSwoopDispatcher: =>
          store.isSwoop() and not store.isRoot() and store.hasRole('swoop_dispatcher')
        isSwoopRoot: =>
          store.isSwoop() and store.isRoot()
        isSuperCompany: =>
          if @user?
            return @user?.company?.type == 'SuperCompany'
          return false

        getMySiteIds: =>
          @user?.site_ids

        siteCodeLabel: =>
          return "Site Code"

        getUserType: =>
            if store.isRoot() or store.isSwoopDispatcher()
              return "root"
            if store.isPartner()
              return "partner"
            if store.isFleet()
              return "fleet"

        getEndpoint: =>
            if store.isRoot() or store.isSwoopDispatcher()
                return "root"
            if store.isFleet()
                return "fleet"
            return "partner"

        getUserUrl: =>
            if store.isRoot() || store.isPartner() || store.isSwoopDispatcher()
              return "partner/dispatch"
            if store.isFleet()
              return "fleet"
            return "login"

        getPasswordRequest: =>
          return Storage.get('password_request')

        forgotPasswordUrl: =>
          return 'forgotPassword'

        routeUser: (data) =>
            @user = data
            Context.navigate UserStore.getUserUrl()
            return

        routeLogin: () =>
            Context.navigate UserStore.getUserUrl()
            return

        routeForgotPassword: () =>
          Context.navigate UserStore.forgotPasswordUrl()
          return

        getVehicleCategories: =>
          return @user?.company?.vehicle_categories

        getUnitSystem: =>
          getUnitSystem(store.getCompany()?.distance_unit)

        getCurrency: =>
          store.getCompany()?.currency

        hasAuthToken: =>
          bearer_token = getBearerToken()
          if bearer_token?
            return true
          else
            return false

        getMe: (cb) =>
            Api.userMeRequest({
              success: (data) =>
                receivedUser(data)
                if cb?
                  cb(data)

              error: (data) =>
                console.warn(data)

            })

        getBearerToken: () =>
          getBearerToken()

    store[key] = val for key, val of triggers
    MicroEvent.mixin( store )
    trigger = store.trigger

    #Any helper functions
    setLocalStorage = (key, value) ->
        Storage.set(key, value)

    getBearerToken = () ->
        return sessionService.getBearerToken()

    getRefreshToken = () ->
        return sessionService.getRefreshToken()

    getExpiresAt = () ->
        return sessionService.getExpiresAt()

    setBearerToken = (token) ->
      if token
        sessionService.setBearerToken(token)

    setRefreshToken = (token) ->
        sessionService.setRefreshToken(token)

    setExpiresAt = (expiresAt) ->
      if expiresAt
        sessionService.setExpiresAt(expiresAt)

    setPasswordRequest = (password_request) ->
        setLocalStorage("password_request", password_request)

    removeBearerCookie = =>
        sessionService.removeBearerToken()
        sessionService.removeRefreshToken()
        sessionService.removeExpiresAt()

    refreshToken = (grabUser, isSuccessfullCallback) =>
        # We should be handling auth0 too, but it's handled elsewhere and the intent is for this
        logging.logInfo('[UserStore] `refreshToken`', {
          refresh_token,
        })
        # function to go away in short order
        if !auth0Service.isAuthenticated()
          if refresh_token?
              Api.refreshTokenRequest(refresh_token,
                  success: (data) =>
                      if not grabUser? || grabUser == false
                          data.keep_user = true
                      Dispatcher.send(RECEIVED_API_KEY, data)
                      isSuccessfullCallback?(true)
                  error: (jqXHR) =>
                    isSuccessfullCallback?(false, jqXHR)
              )
          else
            userService.logout(relayService.environment)

    setBearerCookie = (b, expires_in) =>
        setBearerToken(bearer_token)
        setRefreshToken(refresh_token)
        setExpiresAt(new Date().getTime() + expires_in)
        grabBearer_token()

    grabBearer_token = =>
        b_token = getBearerToken()
        if b_token?
            Api.setBearerToken b_token
            bearer_token = b_token
        r_token = getRefreshToken()
        if r_token?
            refresh_token = r_token
        return bearer_token

    setupTokenRefresh = (data, force, grabUser) =>
        #just refresh every four hour for the heck of it to ensure no breaks
        expiration = 4 * 60 * 60
        #if data? && data.expires_in?
            #expiration = parseInt(data.expires_in)
        if @refreshInterval?
            clearInterval(@refreshInterval)

        if !auth0Service.isAuthenticated()
          @refreshInterval = setInterval(refreshToken, expiration * 1000)
          if force
              #wait until everything is set up to refresh
              setTimeout(partial(refreshToken, grabUser), 60*1000)
              #refreshToken(grabUser)

    grabAndSetUser = (cb, force=false) =>
        if grabBearer_token() && !@fetchingUser && (!@user || force)
          @fetchingUser = true
          Api.getUser(
              success: (data) =>
                @[RECEIVED_USER](data)
                @fetchingUser = false
                if cb?
                    cb(data)

              error: (data) =>
                  @fetchingUser = false
                  @error = data
                  store.trigger(CHANGE)
          )



    clearError = =>
        @error = null

    receivedUser = @[RECEIVED_USER] = (pdata) =>
        @user = extend({}, @user, pdata)

        if store.isRoot()
            document.body.classList.add("root")

        if pdata?.timezone?
          moment.tz?.setDefault?(pdata.timezone)
        else
          callWhen(
            () => swoop?.store?.Users?.updateItem?,
            () =>
              if pdata
                swoop.store.Users.updateItem({ id: pdata.id, timezone: moment.tz.guess() })
            ,
            5000
          )

        trackingIdentify()

        store.trigger(LOGIN)

    @[RECEIVED_API_KEY] = (pdata) =>
      bearer_token = pdata.access_token
      refresh_token = pdata.refresh_token
      currentTimestamp = new Date().getTime()
      expires_at = currentTimestamp + pdata.expires_in

      logging.logInfo("[UserStore] RECEIVED_API_KEY event received", {
        bearer_token: bearer_token.slice(-5),
        currentTimestamp,
        expires_at,
        refresh_token: refresh_token.slice(-5),
      })

      setBearerCookie bearer_token, pdata.expires_in
      Api.setBearerToken bearer_token
      Api.setExpiresAt expires_at
      setupTokenRefresh(pdata)
      clearError()

      if not pdata.keep_user
          grabAndSetUser( (data) =>
              if pdata.preventRouting
                return
              store.routeUser data
          , true)

    @[RECEIVED_LOGIN] = ({ username, password, preventRouting }) =>
        logging.logInfo("[UserStore] RECEIVED_LOGIN event received", { username })
        clearError()
        removeBearerCookie()
        Api.clearBearerToken()
        Api.login(username, password,
          success: (data) =>
            data.preventRouting = preventRouting
            Dispatcher.send(RECEIVED_API_KEY, data)
          ,
          error: (data) =>
            @error = data
            store.trigger(CHANGE)
        )

    store.cleanup = () =>
      removeBearerCookie()
      clearError()
      @user = null
      @error = null
      #hack to get rid of back drop added by bootstrap
      document.querySelector('.modal-backdrop.fade.in')?.remove()

      store.trigger(LOGOUT)

    store.trackingIdentify = trackingIdentify = (user, job=null) =>
      if !user?
        user=UserStore.getUser()
      if user
        logging?.identify(user)

    if !init_data?
      #Listen for events
      Dispatcher.register(( payload ) =>
          if not payload.eventName
            console.warn('empty Event '+JSON.stringify(payload))
          if typeof @[payload.eventName] is 'function'
              @[payload.eventName](payload.data)

          true
      )

      #initial setup
      grabBearer_token()
      if bearer_token? and bearer_token.length > 0
        grabAndSetUser()

    return store
)

UserStore = UserStoreFunc.bind({})()

window.UserStore = UserStore

export default UserStore
