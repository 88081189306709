import { Auth0Client, AuthenticationError } from '@auth0/auth0-spa-js' // auth0-spa
import { graphql, commitMutation } from 'react-relay'
import dataDog from 'shared/utils/logging/integrations/datadog'
import logging from 'shared/utils/logging'
import sessionService from 'web-client/utils/session'
import type { Environment } from 'react-relay'
import type { IdToken } from '@auth0/auth0-spa-js'
import browser from '../browser'
import 'core-js/features/url-search-params'

import type { auth0RecordLoginMutation } from './__generated__/auth0RecordLoginMutation.graphql'

type Auth0ConnectionType = {
  connection: string
  access_type?: string
}

const validateLoginMutation = graphql`
  mutation auth0RecordLoginMutation($input: RecordLoginInput!) {
    recordLogin(input: $input) {
      user {
        id
      }
    }
  }
`

type Auth0Service = {
  clear: () => void
  client: Auth0Client | null
  fetch: () => Promise<IdToken | undefined>
  getIdTokenClaims: () => Promise<IdToken | undefined>
  getToken: () => Promise<string>
  implementation: {
    validate: () => void
  }
  initialize: () => void
  inject: (methodName: keyof Auth0Service['implementation'], method: () => void) => void
  isAuthenticated: () => boolean | Promise<boolean>
  load: (idClaim: IdToken | undefined) => void
  login: (relayEnvironment: Environment) => Promise<void>
  looksLikeResponse: () => boolean
  redirect: (ssoConnectionName: string) => void
  refresh: () => Promise<string>
  refreshTimer: NodeJS.Timeout | null
  setBearerToken: (token: string) => void
  setExpiresAt: (expiresAt: number | null) => void
  validate: (relayEnvironment: Environment) => Promise<boolean>
}

const auth0Service: Auth0Service = {
  client: null,
  refreshTimer: null,

  implementation: {
    validate() {
      throw new Error('Not implemented')
    },
  },

  inject(methodName, method) {
    auth0Service.implementation[methodName] = method
  },

  initialize() {
    if (auth0Service.client) {
      return
    }

    const { SSO_AUTH0_CLIENT_ID, SSO_AUTH0_DOMAIN } = window.ENV || {}
    const isAuth0KeysAvailable = !!(SSO_AUTH0_CLIENT_ID && SSO_AUTH0_DOMAIN)

    if (isAuth0KeysAvailable) {
      auth0Service.client = new Auth0Client({
        client_id: SSO_AUTH0_CLIENT_ID,
        domain: SSO_AUTH0_DOMAIN,
        redirect_uri: `${window.ENV?.SITE_URL}/login`,
        scope: 'openid profile email',
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
      })
    }
  },

  looksLikeResponse() {
    return new URLSearchParams(window.location.search).has('code')
  },

  getToken() {
    auth0Service.initialize()
    return auth0Service.client?.getTokenSilently() ?? Promise.resolve('')
  },

  getIdTokenClaims() {
    auth0Service.initialize()
    if (!auth0Service.client) {
      throw new Error('Auth0 client not initialized')
    }

    return auth0Service.client.getIdTokenClaims()
  },

  isAuthenticated() {
    if (sessionService.getBearerToken()?.startsWith('auth0')) {
      auth0Service.initialize()
      return auth0Service.client?.isAuthenticated() ?? false
    } else {
      return false
    }
  },

  async login(relayEnvironment) {
    try {
      const token = await auth0Service.getToken()
      if (!token) {
        logging.logError('Missing Auth0 Token', { method: 'login' })
        throw new Error('Missing Auth0 Token')
      }

      const idClaim = await auth0Service.getIdTokenClaims()

      if (!idClaim) {
        throw new Error('Missing Auth0 Claims')
      }

      dataDog.mergeProps({ user: { email: idClaim.email } })

      logging.logInfo('[auth0Service] login - id claim received', {
        alreadyExpired: idClaim.exp && Date.now() > idClaim.exp,
        authTime: idClaim.auth_time,
        email: idClaim.email,
        emailVerified: idClaim.email_verified,
        expiresAt: idClaim.exp,
        token: `...${idClaim.__raw.slice(-5)}`,
      })
      auth0Service.load(idClaim)

      logging.logInfo('Redirect response - Sucessfully parsed Auth0 token', {
        feature: 'SSO',
      })
    } catch (e) {
      if (!(e instanceof Error)) {
        throw e
      }

      browser.clearQuery()
      const message = 'Redirect response - Failed to parse Auth0 Login Token'
      dataDog.logError(message, {
        feature: 'SSO',
        error: {
          ...(e instanceof AuthenticationError && { code: e.error }),
          message: e.message,
          stack: e.stack,
        },
      })
      sessionService.removeId()
      throw e
    }

    try {
      dataDog.logInfo('[auth0Service] validate`', {
        hasBearerToken: sessionService.hasBearerToken(),
        token: `...${sessionService.getBearerToken()?.slice(-5)}`,
        expiresAt: sessionService.getExpiresAt(),
        id: sessionService.getId(),
        mfaRememberToken: sessionService.getMfaRememberToken(),
        feature: 'SSO',
      })
      const isValid = await auth0Service.validate(relayEnvironment)
      if (isValid) {
        logging.logInfo('Redirect response - User authenticated successfully', {
          feature: 'SSO',
        })
        sessionService.removeId()
      } else {
        browser.clearQuery()
        const message = 'Redirect response - User unauthorized'
        dataDog.logWarning(message, {
          feature: 'SSO',
        })
        sessionService.removeId()
        throw new Error('User not found')
      }
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }

      browser.clearQuery()
      dataDog.logError(error.message, {
        error,
        feature: 'SSO',
      })
      sessionService.removeId()
      throw error
    }
  },

  setBearerToken(token) {
    if (token) {
      sessionService.setBearerToken(`auth0/${token}`)
    } else {
      logging.logError('Missing Auth0 Token', { method: 'setBearerToken' })
    }
  },

  setExpiresAt(expiresAt) {
    if (expiresAt) {
      sessionService.setExpiresAt(expiresAt)
    } else {
      logging.logError('Missing Auth0 Token Expiry', { method: 'setExpiresAt' })
    }
  },

  redirect(ssoConnectionName) {
    auth0Service.initialize()
    if (auth0Service.client) {
      const connectionObject: Auth0ConnectionType = { connection: ssoConnectionName }

      if (ssoConnectionName.includes('/')) {
        const [connection, accessType] = ssoConnectionName.split('/')
        connectionObject.connection = connection
        connectionObject.access_type = accessType
      }

      void auth0Service.client.loginWithRedirect(connectionObject)
    } else {
      logging.logError('Auth0 client not initialized', { method: 'redirect' })
    }
  },

  async fetch() {
    const token = await auth0Service.getToken()
    if (!token) {
      logging.logError('Missing Auth0 Token', { method: 'fetch' })
      throw new Error('Missing Auth0 Token')
    }
    const idClaim = await auth0Service.getIdTokenClaims()
    return idClaim
  },

  load(data) {
    if (!data) {
      logging.logWarning('Auth0 - Missing Claims', { feature: 'SSO' })
      return
    }

    auth0Service.setBearerToken(data.__raw)
    auth0Service.setExpiresAt(data.exp ? data.exp * 1000 : null)
  },

  async refresh() {
    try {
      const data = await auth0Service.fetch()
      auth0Service.load(data)
      return `auth0/${data?.__raw}`
    } catch (error) {
      if (!(error instanceof Error)) {
        throw error
      }

      const sessionTimeout = parseInt(
        sessionService.get('session-timeout-at', { skipCache: true }) || '',
        10
      )
      const currentTimestamp = new Date().getTime()
      const isAuthenticated = await auth0Service.isAuthenticated()
      const errorCode = error instanceof AuthenticationError && error.error

      const logPayload = {
        feature: 'SSO',
        error: {
          ...(errorCode && { code: errorCode }),
          message: error.message,
        },
      }

      if (errorCode === 'login_required' && isAuthenticated) {
        dataDog.logWarning(
          'Request was made after user has logged out from another tab',
          logPayload
        )
      } else if (errorCode === 'login_required' && !isAuthenticated) {
        dataDog.logWarning('Request was made after user has logged out', logPayload)
      } else if (!isNaN(sessionTimeout) && currentTimestamp >= sessionTimeout) {
        dataDog.logWarning('Refresh occurred on session that had timed out', logPayload)
      } else if (errorCode === 'server_error') {
        dataDog.logError('Refresh resulted in Auth0 server error', logPayload)
      } else {
        dataDog.logError('Failed to refresh Auth0 token', logPayload)
      }
      throw error
    }
  },

  clear() {
    sessionService.removeBearerToken()
    sessionService.removeExpiresAt()
  },

  validate(relayEnvironment) {
    return new Promise((resolve, reject) => {
      const variables = {
        input: {},
      }
      const onCompleted = (_: unknown, errors: unknown) => {
        if (errors) {
          resolve(false)
        } else {
          resolve(true)
        }
      }
      const onError = (error: Error) => {
        reject(error)
      }
      commitMutation<auth0RecordLoginMutation>(relayEnvironment, {
        mutation: validateLoginMutation,
        variables,
        onCompleted,
        onError,
      })
    })
  },
}

export default auth0Service
