import Consts from 'consts'
import Storage from 'web-client/utils/storage'
import logging from 'shared/utils/logging'
import { MFA_REMEMBER_TOKEN } from 'web-client/utils/session'

// HACK: There is bad state from previous login attempts. this is a patch for the issue
// TODO: Address root issue of bad, leftover state
const clearStorage = (feature?: string) => {
  logging.logInfo('Clearing Storage', feature ? { feature } : {})
  Storage.getAllKeys()
    .filter(
      (key) =>
        key !== MFA_REMEMBER_TOKEN &&
        !key.endsWith('dashboardTabs') &&
        key !== Consts.LOGIN_FLASH_ERROR_MESSAGE_KEY
    )
    .forEach((key) => {
      Storage.remove(key)
    })
}

export default clearStorage
