import styled from '@emotion/styled'
import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'

const ModalContent = styled.div`
  color: ${colors.grays.l1};
  flex: 1 1 auto;
  font-size: ${fontSizes.body};
  overflow-y: auto;
  padding: 0;
`

export default ModalContent
