import type { ReactNode } from 'react'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { graphql, useSubscription } from 'relay-hooks'
import { useRouter } from 'react-router5'
import Consts from 'consts'
import logging from 'shared/utils/logging'
import styled from '@emotion/styled'
import fontWeights from 'design-system/tokens/fontWeights'
import relayService from 'shared/utils/relay'
import workerTimers from 'web-client/utils/workerTimers'

import { sendCustomNotification } from 'design-system/components/Notifications/notificationUtils'
import type {
  useSwoopVersionUpdatedSubscription,
  useSwoopVersionUpdatedSubscription$data,
} from './__generated__/useSwoopVersionUpdatedSubscription.graphql'

type UseSwoopVersionState = {
  refreshRequired: boolean
  version: string
  serverVersion: string
}

const SwoopVersionContext = createContext<UseSwoopVersionState>({
  refreshRequired: false,
  version: window.VERSION,
  serverVersion: window.VERSION,
})

const subscription = graphql`
  subscription useSwoopVersionUpdatedSubscription {
    versionUpdated {
      version
      urgent
    }
  }
`

const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  font-weight: ${fontWeights.bold};
`

const NotificationWrapper = styled.span`
  text-align: left;
  line-height: 18px;
`

export const ForceRefreshNotification = () => {
  const [time, setTime] = useState(Consts.FORCE_REFRESH_COUNTDOWN)

  useEffect(() => {
    workerTimers.setInterval(() => {
      setTime((prev) => (prev > 0 ? prev - 1 : prev))
    }, 1000)
  }, [])

  useEffect(() => {
    if (time <= 0) {
      location.reload()
    }
  })
  return (
    <NotificationWrapper className="message">
      You have <em>{time} seconds</em> to save your work and refresh the page. This is required to
      process a critical application update.
      <LinkButton onClick={() => location.reload()} type={'button'}>
        Refresh Now
      </LinkButton>
    </NotificationWrapper>
  )
}

export const SwoopVersionProvider = ({ children }: { readonly children?: ReactNode }) => {
  const [state, setState] = useState<UseSwoopVersionState>({
    refreshRequired: false,
    serverVersion: window.VERSION,
    version: window.VERSION,
  })
  const router = useRouter()

  const checkSoftVersionUpdate = useCallback(
    (version: string, timeout = 0) => {
      if (version !== window.VERSION) {
        logging.logInfo('Soft refresh required due to new version')
        // On navigation force a refresh
        workerTimers.setTimeout(() => {
          if (router) {
            router.useMiddleware(
              () => (toState: { path: string }, fromState: { path: string }, done: () => void) => {
                if (fromState?.path && fromState.path !== toState.path) {
                  window.location.href = `${toState.path}`
                  return false
                }
                done()
              }
            )
            setState({
              refreshRequired: true,
              serverVersion: version,
              version: window.VERSION,
            })
          } else {
            logging.logError('Soft refresh required but no router found')
          }
        }, timeout)
      }
    },
    [router]
  )

  useEffect(() => {
    return relayService.onVersionReceived((version: string) => {
      checkSoftVersionUpdate(version, 1000 * 60 * 10)
    })
  }, [checkSoftVersionUpdate])

  const onVersionUpdated = useCallback((data?: useSwoopVersionUpdatedSubscription$data | null) => {
    if (!data) {
      return
    }
    const { version, urgent } = data.versionUpdated
    const refreshRequired = version !== window.VERSION
    checkSoftVersionUpdate(version)

    // Set the router state so other downstream components can also refresh
    setState({
      refreshRequired,
      serverVersion: version,
      version: window.VERSION,
    })

    // When it's urgent, show countdown
    if (urgent && refreshRequired) {
      workerTimers.setTimeout(
        () => {
          sendCustomNotification(<ForceRefreshNotification />, Consts.NOTIFICATION_TYPES.ERROR)
        },
        Math.random() * Consts.FORCE_REFRESH_RANDOM_WAIT_TIME * 1000
      )
    }
  }, [])

  useSubscription<useSwoopVersionUpdatedSubscription>(
    useMemo(
      () => ({
        subscription,
        onNext: onVersionUpdated,
        variables: {},
      }),
      [onVersionUpdated]
    )
  )

  return <SwoopVersionContext.Provider value={state}>{children}</SwoopVersionContext.Provider>
}

const useSwoopVersion = () => {
  const context = useContext(SwoopVersionContext)
  if (context === undefined) {
    throw new Error('useSwoopVersion must be used within a SwoopVersionProvider')
  }
  return context
}

export default useSwoopVersion
