import React, { createContext, type PropsWithChildren, useContext } from 'react'

const ModalProps = createContext<{ id: string | number } | null>(null)

const ModalPropsProvider = ({ children, ...props }: PropsWithChildren<{ id: string | number }>) => {
  return <ModalProps.Provider value={props}>{children}</ModalProps.Provider>
}

export const useModalProps = () => {
  return useContext(ModalProps)
}

export default ModalPropsProvider
