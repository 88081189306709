import type { ReactNode } from 'react'
import React from 'react'

import Consts from 'consts'
import colors from 'design-system/tokens/colors'
import fontSizes from 'design-system/tokens/fontSizes'
import fontWeights from 'design-system/tokens/fontWeights'

import { Icon } from 'design-system/components/Icon'
import IconButton from 'design-system/components/IconButton'

import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'

import type { Id, ToastOptions } from 'react-toastify'
import { ToastContainer as BaseToastContainer, toast, Slide } from 'react-toastify'
import { localize } from 'shared/utils/languageUtils'
import styled from '@emotion/styled'
import fonts from 'design-system/tokens/fonts'

export type Type =
  | typeof Consts.NOTIFICATION_TYPES.INFO
  | typeof Consts.NOTIFICATION_TYPES.SUCCESS
  | typeof Consts.NOTIFICATION_TYPES.ERROR
  | typeof Consts.NOTIFICATION_TYPES.WARNING

const zIndex = 999999999999999
const iconSize = 'xsmall'

const getIcon = (type: Type) => {
  switch (type) {
    case Consts.NOTIFICATION_TYPES.ERROR:
      return <Icon color={colors.alerts.red} icon={faExclamationCircle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.SUCCESS:
      return <Icon color={colors.alerts.green} icon={faCheckCircle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.WARNING:
      return <Icon color={colors.alerts.yellow} icon={faExclamationTriangle} iconSize={iconSize} />
    case Consts.NOTIFICATION_TYPES.INFO:
    default:
      return <Icon color={colors.primary} icon={faInfoCircle} iconSize={iconSize} />
  }
}

export const sendCustomNotification = (
  template: ReactNode,
  type: Type = Consts.NOTIFICATION_TYPES.INFO,
  opts: ToastOptions<unknown> | undefined = undefined
) => {
  const autoClose = opts?.autoClose || 3000

  const id = toast(template, {
    type,
    icon: () => getIcon(type),
    autoClose: type === Consts.NOTIFICATION_TYPES.ERROR ? false : autoClose,
    ...opts,
  })

  return id
}

export const sendNotification = (
  template: string,
  type: Type = Consts.NOTIFICATION_TYPES.INFO,
  opts: ToastOptions<unknown> | undefined = undefined
) => {
  // localize or interpolate the message
  const localizedMessage = localize(template)

  if (localizedMessage !== '') {
    const baseToastId = toast.isActive(localizedMessage)
      ? `${localizedMessage} ${Math.random()}`
      : localizedMessage

    const autoClose = opts?.autoClose || 3000
    const toastId = opts?.toastId || baseToastId

    const id = toast(localizedMessage, {
      type,
      icon: () => getIcon(type),
      autoClose: type === Consts.NOTIFICATION_TYPES.ERROR ? false : autoClose,
      toastId,
      ...opts,
    })

    return id
  }
}

// The ID is the localized message itself unless you pass a custom toastId in the opts, or the message is duplicated which adds a random number at the end, you can get it by storing the returned value from the sendNotification function
export const removeNotification = (id: Id) => {
  toast.dismiss(id)
}

const ToastContainer = styled(BaseToastContainer)`
  --toastify-color-info: ${colors.primary};
  --toastify-color-success: ${colors.alerts.green};
  --toastify-color-warning: ${colors.alerts.yellow};
  --toastify-color-error: ${colors.alerts.red};
  --toastify-toast-width: 508px;
  --toastify-toast-min-height: 44px;
  --toastify-font-family: ${fonts.body};
  --toastify-z-index: ${zIndex};
  --toastify-text-color-light: ${colors.black};
  --toastify-toast-padding: 10px 14px 12px 14px;

  .Toastify__toast {
    align-self: center;
    height: fit-content;
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: ${fontWeights.normal};
    font-size: ${fontSizes.body};

    .Toastify__progress-bar--wrp {
      height: 2px;
      border-radius: 0 0 6px 6px;
    }

    button {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      height: 20px;
    }
  }
`

export const NotificationsProvider = () => (
  <ToastContainer
    closeButton={(props) => (
      <IconButton
        color={colors.grays.l2}
        icon={faTimes}
        iconSize="medium"
        onClick={props.closeToast}
      />
    )}
    newestOnTop
    position="top-center"
    role="alert"
    theme="light"
    transition={Slide}
  />
)
